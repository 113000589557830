import { Dropdown, Layout, Menu, Space, Typography, theme } from "antd";
import {
  UserOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useDashboard, useOrgDetails } from "../../api/useDashboard";
import "./dashboard.css";
import React, { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

export function Dashboard() {
  const [collapsed, setCollapsed] = useState(false);
  const { userDetails } = useDashboard();
  const { orgDetails } = useOrgDetails(userDetails);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const sidebarItems = useMemo(() => {
    return [
      {
        key: "/",
        icon: <UserOutlined />,
        label: "Users",
        onClick: () => navigate("/"),
      },
      {
        key: "/users/invite",
        icon: <UsergroupAddOutlined />,
        label: "Invite Users",
        onClick: () => navigate("/users/invite"),
      },
      {
        key: "/settings",
        icon: <SettingOutlined />,
        label: "Settings",
        onClick: () => navigate("/settings"),
      },
    ];
  }, [navigate]);

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="1" icon={<HomeOutlined />}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<LogoutOutlined />}>
        <Link to={`/logout?redirect_uri=/login`}> Log out </Link>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (!userDetails || !orgDetails) return;
    if (!orgDetails.isVerified && !userDetails.role === "admin")
      throw new Error("Error occured in verification and user role");
    if (!userDetails.isVerified && userDetails.role === "admin") {
      navigate("/orgOnboarding");
      return;
    }
    if (!userDetails.isVerified) {
      navigate("/onboarding");
      return;
    }
  }, [userDetails, orgDetails]);

  if (!userDetails) return <></>;

  return (
    <Layout className="dashboard">
      <Sider
        theme="dark"
        breakpoint="lg"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed) => {
          setCollapsed(collapsed);
        }}
        collapsedWidth={64}
        trigger={null}
        style={{
          overflow: "auto",
          height: "100vh",
          background:"var(--col-sidebar)",
          backgroundColor:"var(--col-sidebar)",
        }}
        collapsible
        collapsed={collapsed}
      >
        <Space align="center" className="logo" style={{ display: "none" }}>
          <Avatar src={orgDetails ? `${orgDetails.pic}/m` : null} />
          {!collapsed && (
            <Title level={5} style={{ color: "white", margin: "0px" }}>
              Roomie Login
            </Title>
          )}
        </Space>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          items={sidebarItems}
          style={{ backgroundColor: "var(--col-sidebar)" }}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="header"
          style={{
            padding: 0,
            borderBottom: "1px solid #dedede",
            display: "none",
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => {
                setCollapsed(!collapsed);
              },
            }
          )}

          <div className="menu-section">
            <Dropdown overlay={dropdownMenu}>
              <div>
                <Avatar
                  size="small"
                  className="avatar"
                  src={userDetails.pic + "/l"}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: "#ede5fb", color: "#650df2" }}
                />
                {userDetails.name}
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="content"
          style={{
            // margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
