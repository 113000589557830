import AppStore from "../app/store";
import { setUrl } from "../features/externalNavigation/externalNavigationSlice";

export function isDesktopApp() {
  return !!window.AndroidClient && !!window.AndroidClient.downloadFile;
}

export function sendOpenLinkIPCSignal(link) {
  if (!isDesktopApp()) return;
  window.AndroidClient.openLink({ link });
}

window.addEventListener("message", (event) => {
  const msg = event.data;
  switch (msg.type) {
    case "uri-change":
      AppStore.dispatch(setUrl(msg.url));
      break;
    default:
    // console.log("Unknown message type", msg);
  }
});
