import { useEffect, useState } from "react";
import { Card, Carousel, Divider, Image, message } from "antd";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { UserOutlined, LockOutlined, TeamOutlined } from "@ant-design/icons";

import "./login.css";
import { getTeam, login } from "../../api/login";
import { useLocation, useNavigate } from "react-router-dom";
import { useDashboard } from "../../api/useDashboard";
import { useOAuth } from "../../api/useOAuth";
import { isDesktopApp, sendOpenLinkIPCSignal } from "../../roomie/ipc";

export function Login() {
  const [teamName, setTeamName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { redirectUri, clientID, state, nonce } = useOAuth();
  const navigate = useNavigate();

  const { userDetails } = useDashboard(false);

  const validateTeam = async (team) => {
    setIsLoading(true);
    try {
      const { name } = await getTeam(team);
      setTeamName(name);
      setIsLoading(false);
    } catch (err) {
      if (err.status === 404) {
        message.error("No such Team / Organization");
      }
    }
    setIsLoading(false);
  };

  const onFinish = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { redirect_uri } = await login(
        { email, pwd: password },
        { redirectUri, clientID, state, nonce }
      );
      navigate(redirect_uri);
    } catch (err) {
      message.error("Email or password incorrect");
    }
    setIsLoading(false);
  };

  const handleOpenDesktopApp = () => {
    setIsLoading(true);
    sendOpenLinkIPCSignal(googleLoginURL);
  };

  useEffect(() => {
    if (!userDetails) return;
    navigate("/");
  }, [userDetails]);

  const googleLoginURL = `${window.location.origin}/api/getGoogleUrl?redirect_uri=${redirectUri}&client_id=${clientID}&state=${state}&nonce=${nonce}&orgname=${teamName}`;

  return (
    <div className="login">
      <img className="logo" src="/logo109.png"></img>
      <div className="login-container">
        <div className="side-image">
          <img
            src="/login-screen.svg"
            style={{ maxHeight: "456px", maxWidth: "413px" }}
          />
        </div>
        <div className="form-section">
          <div className="title-section">
            <h2>Welcome back to Roomie!</h2>
            <p>Please fill the information</p>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <>
              <div className="sign-in-with-google">
                <Button
                  type="link"
                  href={isDesktopApp() ? null : googleLoginURL}
                  icon={<img src="/google-logo.png"></img>}
                  onClick={isDesktopApp() ? handleOpenDesktopApp : undefined}
                  style={{ display: "flex" }}
                >
                  login with google
                </Button>
              </div>
              <p>OR</p>
              <Form.Item
                name="email"
                type="email"
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <div className="input">
                  <span>Work Email</span>
                  <Input
                    style={{
                      height: "50px",
                      border: "1px solid #3E3D4B",
                      textAlign: "left",
                      borderRadius: "5px",
                      background: "white",
                      padding: "15px 16px",
                      fontSize: "14px",
                      color: "#2727278",
                      marginTop: "4px",
                      marginBottom: "16px",
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                style={{ margin: 0 }}
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <div className="input">
                  <span>Password (min 8 length)</span>
                  <Input.Password
                    type="password"
                    style={{
                      height: "50px",
                      border: "1px solid #3E3D4B",
                      textAlign: "left",
                      borderRadius: "5px",
                      background: "white",
                      padding: "15px 16px",
                      fontSize: "14px",
                      color: "#2727278",
                      marginTop: "4px",
                      marginBottom: "8px",
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>
                    <div className="text">Remember me</div>
                  </Checkbox>
                </Form.Item>

                <a className="login-form-forgot text" href="/users/forgotPwd">
                  Forgot password
                </a>
              </Form.Item>

              <button type="submit" className="login-form-button">
                Log in
              </button>
            </>
          </Form>
          <div className="donot-have-account">
            Don't have an account? <a href="/orgSignUp"> Create workspace</a>
          </div>
        </div>
      </div>
    </div>
  );
}
